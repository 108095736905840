'use strict';

angular.module('mvpcarrefourApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('main.vote.category', {
                url: '/{slugCategory}',               
                views: {
                    'vote': {
                        templateUrl: 'app/category/category.html',
                        controller: 'CategoryCtrl'
                    }
                }
            })            
            .state('main.vote.category.product', {
                url: '/{slugProduct}',
                onEnter: function($state, $stateParams, $compile, $timeout, $rootScope, Auth) {
                    let slug = $stateParams.slugProduct;
                    $timeout(function() {
                        angular.element(document).find('#' + slug).trigger('click');
                        if (!angular.isUndefined($rootScope)) {
                            //Si hay intencion de voto desde el detalle del producto
                            if (!angular.isUndefined($rootScope.votePreLogin) && Auth.isLoggedIn()) {                              
                                // Reproducimos el evento click sobre el producto votado.
                                angular.element(document).find('#btn-detail-' + $rootScope.votePreLogin.product.slug).trigger('click');
                            }
                        }
                    }, 500);
                }
            })

    });
